import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface modalStateSlice {
	icon?: any;
	isVisible: boolean;
	// setIsVisible?: (arg: boolean) => void;
	setIsVisible?: any;
	body: string;
	subBody: string;
	mainFunction?: any;
	subFunction?: any;
	mainActionTitle: string;
	subActionTitle: string;
	selectedPackage?: string;
	modalStyle?: string;
}

const initialState: modalStateSlice = {
	icon: null,
	isVisible: false,
	setIsVisible: null,
	body: '',
	subBody: '',
	mainFunction: null,
	subFunction: null,
	mainActionTitle: '',
	subActionTitle: '',
	selectedPackage: '',
	modalStyle: ''
};

const modalSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		setModalVisible: (state, action: PayloadAction<modalStateSlice>) => {
			return action.payload;
		},
		setModalUnVisible: (state) => {
			return (state = initialState);
		}
	}
});

export const { setModalUnVisible, setModalVisible } = modalSlice.actions;

export default modalSlice.reducer;
