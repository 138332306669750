import { packageTypes } from "@helpers/interfaces";
import { createSlice } from "@reduxjs/toolkit";

const initialState: packageTypes = {
    error: [],
    loading: false,
    data: false,
    item: false
};

const slice: any = createSlice({
    name: "packages",
    initialState,
    reducers: {
        PackageRequest: (packages) => {
            packages.loading = true;
        },
        PackageClear: (packages) => {
            packages.error = false;
            packages.loading = false;
            packages.data = false;
        },
        PackageSuccess: (packages, action) => {
            packages.loading = false;
            packages.data = action?.payload;
        },
        PackageError: (packages, action) => {
            packages.loading = false;
            packages.error = action?.payload;
        },
        PackageDetailsRequest: (packages) => {
            packages.loading = true;
        },
        PackageDetailsClear: (packages) => {
            packages.error = false;
            packages.loading = false;
            packages.item = false;
        },
        PackageDetailsSuccess: (packages, action) => {
            packages.loading = false;
            packages.item = action?.payload;
        },
        PackageDetailsError: (packages, action) => {
            packages.loading = false;
            packages.error = action?.payload;
        }
    }
});

export const { PackageRequest, PackageClear, PackageSuccess, PackageError, PackageDetailsSuccess, PackageDetailsError, PackageDetailsRequest, PackageDetailsClear } = slice.actions;

export default slice.reducer;
