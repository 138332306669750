import { Modal } from "antd";
import { useEffect, useRef } from "react";

interface Props {
    visible: boolean;
    toggleDialog: any;
    title: string;
    children: any;
    style: any;
    parameterType?: any;
}
const DialogContainer: React.FC<Props> = ({ visible, toggleDialog, title, children, style = "" }: any) => {
    const dialogRef = useRef<any>(null);

    useEffect(() => {
        const overlayNode = dialogRef?.current?.element?.firstChild;
        if (visible && overlayNode) {
            overlayNode!.addEventListener("click", toggleDialog);
        }
        return () => {
            overlayNode?.removeEventListener("click", toggleDialog);
        };
    }, [visible]);

    return (
        <div ref={dialogRef}>
            <Modal title={""} open={visible} onCancel={toggleDialog} centered className={style} footer={null}>
                {title && <p className="dialog_title">{title}</p>}
                {children}
            </Modal>
        </div>
    );
};

export default DialogContainer;
