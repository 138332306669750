import { combineReducers } from "@reduxjs/toolkit";
import masterdata from "./reducers/masterdata";
import validationPopup from "./reducers/validationPopup";
import user from "./reducers/user";
import umrahJourney from "./reducers/umrahJourney";
import packages from "./reducers/website/package";
import modal from "./reducers/modal/modal";
import createPackge from "./reducers/website/createPackge";
import authentication from "./reducers/authentication/authentication";
import admin from "./reducers/admin/admin";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user", 'umrahJourney']
};

const rootReducer = combineReducers({ masterdata, user, packages, auth: authentication, admin, validationPopup, createPackge, modal , umrahJourney});
export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
