import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UmrahJourneyInfoType } from "./umrahJourneyType";

interface SliceState {
	umrahJourneyInfo: UmrahJourneyInfoType | null;
	umrahJourneySessionId: string | null;
	umrahJourneyCurrentStep: number;
	umrahJourneySummary: umrahJourneySummaryType;
	umrahJourneyQueryParams: umrahJourneyQueryParamsType;
}

export type umrahJourneySummaryType = {
	visa: umrahJourneySummaryStepType;
	makkahStays: umrahJourneySummaryStepType;
	madinaStays: umrahJourneySummaryStepType;
	transportation: umrahJourneySummaryStepType;
	groundService: umrahJourneySummaryStepType;
	mutamer: umrahJourneySummaryStepType;
};

export type umrahJourneySummaryStepType = {
	status: "finish" | "wait";
	amount: number;
};

type umrahJourneyQueryParamsType = {
	makkahStays: any;
	madinahStays: any;
	transportation: any;
	groundService: any;
};

const initialState: SliceState = {
	umrahJourneySessionId: null,
	umrahJourneyInfo: null,
	umrahJourneyCurrentStep: 3,
	umrahJourneyQueryParams: {
		makkahStays: {},
		madinahStays: {},
		transportation: {},
		groundService: {}
	},
	umrahJourneySummary: {
		visa: {
			status: "wait",
			amount: 0.0
		},
		makkahStays: {
			status: "wait",
			amount: 0.0
		},
		madinaStays: {
			status: "wait",
			amount: 0.0
		},
		transportation: {
			status: "wait",
			amount: 0.0
		},
		groundService: {
			status: "wait",
			amount: 0.0
		},
		mutamer: {
			status: "wait",
			amount: 0.0
		}
	}
};
const slice = createSlice({
	name: "umrahJourney",
	initialState,
	reducers: {
		setUmrahJourneyInfo: (state, action) => {
			state.umrahJourneyInfo = { ...state.umrahJourneyInfo, ...action.payload };
		},
		setUmrahJourneySummary: (state, action: PayloadAction<{ step: keyof umrahJourneySummaryType; amount: number; status?: "finish" | "wait" }>) => {
			state.umrahJourneySummary[action?.payload?.step]["amount"] = action.payload.amount;
			state.umrahJourneySummary[action?.payload?.step]["status"] = action.payload.status ?? "finish";
		},
		resetUmrahJourneySummary: (state) => {
			for (let key in state.umrahJourneySummary) {
				state.umrahJourneySummary[key as keyof umrahJourneySummaryType]["amount"] = 0;
				state.umrahJourneySummary[key as keyof umrahJourneySummaryType]["status"] = "wait";
			}
		},

		resetUmrahJourneyInfo: (state) => {
			state.umrahJourneyInfo = null;
		},
		setUmrahJourneyCurrentStep: (state, { payload }) => {
			state.umrahJourneyCurrentStep = payload;
		},
		setUmrahJourneyQueryParams: (state, action: PayloadAction<{ queryType: keyof umrahJourneyQueryParamsType; data: any }>) => {
			if (state.umrahJourneyQueryParams?.[action?.payload?.queryType] !== undefined) {
				state.umrahJourneyQueryParams[action?.payload?.queryType] = action?.payload?.data;
			}
			// state.umrahJourneyQueryParams = action?.payload?.data;
		},
		resetUmrahJourneyQueryParams: (state) => {
			for (let key in state.umrahJourneyQueryParams) {
				state.umrahJourneyQueryParams[key as keyof umrahJourneyQueryParamsType] = null;
			}
		},
		setUmrahJourneSessionId: (state, { payload }) => {
			state.umrahJourneySessionId = payload;
		}
	}
});

export const {
	setUmrahJourneyInfo,
	setUmrahJourneySummary,
	setUmrahJourneSessionId,
	setUmrahJourneyCurrentStep,
	resetUmrahJourneySummary,
	resetUmrahJourneyInfo,
	setUmrahJourneyQueryParams,
	resetUmrahJourneyQueryParams
} = slice.actions;

export default slice.reducer;
