import { Button } from "antd";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/configureStore";
import { togglePopup } from "store/reducers/validationPopup";
import { RootState } from "store/rootReducer";
import DialogContainer from "./DialogContainer";
const UpdateModalIcon: any = dynamic(() => import("@common/svgs/UpdateModalIcon").then((res: any) => res.UpdateModalIcon));

interface Props {
    translate: { t: any; i18n: any };
}

const UpdateDialog: React.FC<Props> = ({ translate }) => {
    const isOpen = useSelector((state: RootState) => state?.validationPopup?.openPopup?.updatePopup);
    const dispatch = useAppDispatch();
    const { t } = translate;
    const toggleModal = () => {
        dispatch(togglePopup("update"));
    };
    return (
        <DialogContainer style="updateDialogStyle" title="" visible={isOpen} toggleDialog={toggleModal}>
            <div className="body">
                <UpdateModalIcon />
                <h2 className="title">{t("profile.personal.informationUpdated")}</h2>
                <p className="details">{t("profile.personal.underReview")}</p>
                <Button className="closeBtn" onClick={toggleModal}>
                    {t("common.buttons.close")}
                </Button>
            </div>
        </DialogContainer>
    );
};

export default UpdateDialog;
