import dayjs from "dayjs";
import useApi from "@config/network/useApi";
import useLocalState from "./useLocalState";
import { useEffect } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { uTypes } from "@components/profile/package/types/UserTypes";
import { getPageByRole } from "@components/admin/dashboards/utils";
import { useDispatch, useSelector } from "react-redux";
import { setUsedCurrency, setUserLocation } from "store/reducers/user";
import { getLangCodeByLocal } from "@components/shared/utils/getLangCodeByLocal";
import { useTranslation } from "next-i18next";
// import { useGetGeoLocation } from "@queries/masterdata/useMasterdata";
import { RootState } from "store/rootReducer";

function RouteWrapper({ children }: any): any {
	// Configs
	const router = useRouter();
	const IsServer = typeof window === "undefined";
	const dispatch = useDispatch();
	const { i18n } = useTranslation("website");
	const { data: session, status } = useSession();

	// States
	const { localConditions, localState } = useLocalState();
	// const { data: geoLocationData } = useGetGeoLocation({ enabled: !localConditions?.currencyDate });
	const statusInitiated = ["loading", "authenticated"]?.includes(status);
	const currentUser = useSelector((state: RootState) => state?.user?.currentUser);
	// Watchers
	// useEffect(() => {
	// 	if (geoLocationData?.userLocation) {
	// 		dispatch(setUserLocation(geoLocationData?.userLocation));
	// 		localStorage.setItem("userLocation", JSON.stringify(geoLocationData?.userLocation));
	// 	}
	// }, [geoLocationData?.userLocation]);

	// useEffect(() => {
	// 	if (!localConditions?.currencyDate || !localConditions?.currencyList) {
	// 		const api = useApi("PaymentAPI", {
	// 			headers: {
	// 				"Accept-Language": getLangCodeByLocal(i18n.language)
	// 			}
	// 		});
	// 		api.get(`/Currency/GetAllCurrencies`).then((res) => {
	// 			localStorage.setItem("CurrencyList", JSON.stringify(res?.responseData));
	// 			// Set currency date to local storage with tomorrow date
	// 			localStorage.setItem("CurrencyDate", JSON.stringify(dayjs().format()));
	// 			const findCurrency = res?.responseData?.find((currency: any) => currency?.code === localState?.userCurrency?.code);
	// 			localStorage.setItem("UserCurrency", JSON.stringify(findCurrency));
	// 			dispatch(setUsedCurrency(findCurrency));
	// 		});
	// 	}
	// 	// If local currency is set before and user currency is not set before, set it to local storage and redux store
	// 	if (localConditions?.userCurrency && IsServer === false && localConditions?.currencyList) {
	// 		// if languages is changed and user currency is set before, set it to local storage and redux store
	// 		const findCurrency = localState?.currencyList?.find((currency: any) => currency?.code === localState?.userCurrency?.code);
	// 		localStorage.setItem("UserCurrency", JSON.stringify(findCurrency));
	// 		dispatch(setUsedCurrency(findCurrency));
	// 	}
	// }, []);

	// useEffect(() => {
	// 	// If currency list is set before and user currency is not set before, set user currency to local storage and redux store
	// 	if (localConditions?.currencyList && !localConditions?.userCurrency) {
	// 		// If user currency is not set before, set it to local storage and redux store and set default currency as USD if not found
	// 		let findCurrency = localState?.currencyList?.find((item: any) => item?.code === localState?.userLocation?.code);
	// 		if (!findCurrency) {
	// 			findCurrency = localState?.currencyList?.find((item: any) => item?.code === "USD");
	// 		}
	// 		localStorage.setItem("UserCurrency", JSON.stringify(findCurrency));
	// 		localStorage.setItem("CurrencyDate", JSON.stringify(dayjs().format()));
	// 		dispatch(setUsedCurrency(findCurrency));
	// 	}
	// }, [localState?.currencyList]);

	const Checker = async () => {
		const userRoles = currentUser?.roles || session?.roles;

		if (session && +session?.userType == uTypes?.SuAdmin) {
			return router.push("/admin/security/admins"); // Super Admin
		} else if (session && +session?.userType == uTypes?.Admin && userRoles?.length !== 0) {
			// Admin
			// Get first role and redirect to it
			let role = userRoles?.[0];
			let path = getPageByRole?.[role]?.path;
			let gategory = getPageByRole?.[role]?.gategory;
			if (!path || !gategory) {
				return router.push("/admin/security/admins");
			} else {
				return router.push(`${gategory}${path}`);
			}
		} else if (session && router.pathname?.includes("/auth/")) {
			if ([uTypes?.SuAdmin, uTypes?.Admin]?.includes(+session?.userType)) router.push("/admin/security/admins");
			else router.push("/");
		}
	};
	useEffect(() => {
		Checker();
	}, [router.asPath, session]);

	if (status === "loading") return <></>; // Do nothing while loading
	else if (session && router.pathname?.includes("/auth/") && statusInitiated) return <></>;
	else if (session && [0, 1]?.includes(+session?.userType) && statusInitiated) return <></>;
	else return children;
}
export default RouteWrapper;
