import { createSlice } from "@reduxjs/toolkit";

type DataType = {
	internalID: string;
	packageID: string;
	packageTypeIDs: any[];
	photoIDs: any[];
	packageAddress: {
		countryID: number;
		cityID: number;
		destination: string;
		startingPointLongitude: number;
		startingPointLatitude: number;
		startingPointNote: any;
		startingPointName: any;
	};
	packageAvailability: {
		salesPeriodStartDate: string;
		salesPeriodEndDate: string;
		fixed: boolean;
		startDate: string;
		endDate: string;
		availableSeats: number;
		minNumberOfSeats: number;
		bookedSeats: number;
		duration: number;
	};
	packagePricing: {
		currencyCode: number;
		price: number;
	};
	packageDetails: {
		markets: number[];
		buyerTypes: number[];
		isAllGeographicMarkets: boolean;
		cancellationPolicyType: number;
		geographicMarkets: any[];
		packageTranslations: {
			languageId: number;
			name: string;
			description: string;
			whatIsInclude: string;
		}[];
		packageItems: {
			id: string;
			photoID: any;
			packageItemTypeID: number;
			location: string;
			locationPointLongitude: number;
			locationPointLatitude: number;
			fixedStartDate: any;
			fixedEndDate: any;
			flexibleDayFrom: number;
			flexibleDayTo: number;
      supplierToNotifiyList: any[];
			itemTranslations: {
				language: number;
				title: string;
				description: string;
			}[];
		}[];
	};
};

const initialState: { data: DataType | null; step: number; btnDisabled: Boolean } = {
	data: null,
	btnDisabled: false,
	step: 0
};

const slice = createSlice({
	name: "createPackge",
	initialState,
	reducers: {
		setPackgeDetailes: (createPackge, action) => {
			createPackge.data = action.payload;
		},
		setBtnDisabled: (createPackge, action) => {
			createPackge.btnDisabled = action.payload;
		},
		updatePackgeDetailes: (createPackge, action) => {
			createPackge.data = { ...createPackge?.data, ...action.payload };
		},
		clearPackgeDetailes: (createPackge) => {
			createPackge.data = null;
		},
		removeSupplier: (createPackge, action) => {
			if (createPackge.data) {
        const supplierList = createPackge.data.packageDetails.packageItems[action.payload.index].supplierToNotifiyList
				createPackge.data.packageDetails.packageItems[action.payload.index].supplierToNotifiyList = supplierList.filter((el: any) => el !== action.payload.id);
			}
		}
	}
});

export const { setPackgeDetailes, updatePackgeDetailes, clearPackgeDetailes, setBtnDisabled, removeSupplier } = slice.actions;

export default slice.reducer;
