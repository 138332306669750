import { SelectedVehicle } from "./umrahJourneyType";
import Http from "@config/network/http";
import { AuthenticationAPI as AuthAPI, FilesAPI } from "@common/constants";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Api from "@config/network/ApiConfig";
import { checkoutData } from "./types";

export const fetchUserInfo = createAsyncThunk("user/fetchUserInfo", async (token?: any) => {
	const res = await Http.GET(`${AuthAPI}/Account/GetUserInfo`, false, token || null);
	return res.data.responseData;
});
export const fetchCompanyInfo = createAsyncThunk("Account/fetchCompanyInfo", async (token?: any) => {
	const res = await Http.GET(`${AuthAPI}/Company/GetCompanyProfileInfo`, false, token || null);
	return res.data.responseData;
});
export const editUserInfo = createAsyncThunk("Account/editUserInfo", async (data: any) => {
	const res = await Api.put("AuthenticationAPI", "/Account/EditUserProfile", data);
	if (res?.isSuccess) {
		return res;
	} else {
		throw new Error(res?.responseMessage);
	}
});
export const editUserAvatarInfo = createAsyncThunk("Account/editUserAvatarInfo", async (id: any) => {
	const res = await Api.put("AuthenticationAPI", "/Account/UpdateUserProfileImage", `"${id}"`);
	if (res?.isSuccess) {
		return res;
	} else {
		throw new Error(res?.responseMessage);
	}
});
export const editCompanyAvatarInfo = createAsyncThunk("company/UpdateCompanyProfileImage", async (id: any) => {
	const res = await Api.put("AuthenticationAPI", "/Company/UpdateCompanyProfileImage", `"${id}"`);
	if (res?.isSuccess) {
		return res;
	} else {
		throw new Error(res?.responseMessage);
	}
});
export const editCompanyInfo = createAsyncThunk("Account/editCompanyInfo", async (data: any) => {
	const res = await Http.PUT(`${AuthAPI}/Company/UpdateCompanyProfile`, data);
	return data;
});

export const saveUserImage = createAsyncThunk("Account/saveUserImage", async (key: string) => {
	const res = await Http.POST(`${FilesAPI}/Save/${key}/${key}`);
	return key;
});

export const saveCompanyAttachment = createAsyncThunk("Account/saveCompanyAttachment", async (key: string) => {
	const res = await Http.POST(`${FilesAPI}/Save/${key}/${key}`);
	return key;
});

export const changePassword = createAsyncThunk("Account/changePassword", async (data: any) => {
	return await Http.POST(`${AuthAPI}/Account/ChangePassword`, data);
});

interface UserState {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	countryCode: string;
	nationality: number;
	gender: number;
	dateOfBirth: string;
	password: string;
	isEmailVerify: boolean;
	isCompanyProfileCompleted: boolean;
	isProfileCompleted: boolean;
	userType?: number;
	roles?: any;
	BirthDate?: any;
	image: string;
	isUserProfileCompleted: boolean;
	isCompanyApproved: boolean;
}

interface CompanyState {
	ibaNwalletNumber: string;
	mohNumber: string;
	contactNumber: string;
	countryCode: string;
	companyName: string;
	name: string;
	email: string;
	propertyAddress: number | null;
	profileName: string | null;
	locationPointLongitude: string | null;
	locationPointLatitude: string | null;
	isApproved: boolean;
	isCompanyProfileCompleted: boolean;
	image: string;
	nationality: number;
	attachments?: { id: string; attachmentType: number }[] | null;
	id: string;
}

interface SliceState {
	currentUser: UserState | null;
	currentTheme: any;
	userLocation: any;
	errorMessage: any;
	currentCompany: CompanyState | null;
	subscriptionPackge: any;
	isDrawerOpen: any;
	showCovidWarning: boolean;
	currency: any;
	loading: boolean;
	dialogLoading: boolean;
	userActionBeforeAuth: {
		seats: number | null;
	};
	checkoutData: checkoutData | null;
	selectedVehicles: { items: SelectedVehicle[]; id: number | string } | null;
	lang: string | null;
	selectedB2BRooms: {
		items: any[];
		name: string;
	} | null;
	cartCheckoutSubForm: { fullName: string; phoneNumber: string; email: string } | null;
	cartIsPaid: boolean;
}

const initialState: SliceState = {
	currentUser: null,
	currentTheme: null,
	errorMessage: null,
	currentCompany: null,
	subscriptionPackge: null,
	userLocation: null,
	dialogLoading: false,
	loading: false,
	isDrawerOpen: false,
	currency: null,
	checkoutData: null,
	showCovidWarning: true,
	userActionBeforeAuth: {
		seats: null
	},
	selectedVehicles: null,
	lang: null,
	selectedB2BRooms: null,
	cartCheckoutSubForm: null,
	cartIsPaid: false
};
const slice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setCurrentUser: (user, action) => {
			user.currentUser = action.payload;
		},
		setCurrentTheme: (user, action) => {
			user.currentTheme = action.payload;
		},
		setCompanyProfile: (user, action) => {
			user.currentCompany = action.payload;
		},
		setUserLocation: (user, action) => {
			user.userLocation = action.payload;
		},
		setUsedCurrency: (user, action) => {
			user.currency = action.payload;
		},
		setShowCovidWarning: (user, action) => {
			user.showCovidWarning = action.payload;
		},
		setUserSubscriptionPackge: (user, action) => {
			user.subscriptionPackge = action.payload;
		},
		setDrawerOpen: (user, action) => {
			user.isDrawerOpen = action.payload;
		},
		setUserActionBeforeAuthSeats: (state, { payload }) => {
			const userActionBeforeAuth = { seats: payload };
			state.userActionBeforeAuth = userActionBeforeAuth;
		},
		setCheckoutData: (state, { payload }) => {
			state.checkoutData = payload;
		},
		setSelectedVechiles: (state, { payload }) => {
			state.selectedVehicles = payload;
		},
		setLang(state, { payload }) {
			state.lang = payload;
		},
		setSelectedB2BRooms(state, { payload }) {
			state.selectedB2BRooms = payload;
		},
		setCartCheckoutSubForm(state, { payload }) {
			state.cartCheckoutSubForm = payload;
		},
		setCartIsPaid(state, { payload }) {
			state.cartIsPaid = payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserInfo.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(fetchUserInfo.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
			state.currentUser = { ...action.payload };
			state.loading = false;
		});
		builder.addCase(editUserInfo.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(editUserInfo.rejected, (state, action) => {
			state.loading = false;
			state.errorMessage = action;
		});
		builder.addCase(editUserInfo.fulfilled, (state, action) => {
			state.currentUser = { ...state.currentUser, ...action.payload?.data?.responseData };
			state.loading = false;
		});
		builder.addCase(changePassword.pending, (state, action) => {
			state.dialogLoading = true;
		});
		builder.addCase(changePassword.rejected, (state, action) => {
			state.dialogLoading = false;
		});
		builder.addCase(changePassword.fulfilled, (state, action) => {
			state.dialogLoading = false;
		});
		builder.addCase(saveUserImage.pending, (state, action) => {
			state.dialogLoading = true;
		});
		builder.addCase(saveUserImage.rejected, (state, action) => {
			state.dialogLoading = false;
		});
		builder.addCase(saveUserImage.fulfilled, (state, action) => {
			state.dialogLoading = false;
		});
		builder.addCase(saveCompanyAttachment.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(saveCompanyAttachment.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(saveCompanyAttachment.fulfilled, (state, action) => {
			state.loading = false;
		});
		builder.addCase(fetchCompanyInfo.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(fetchCompanyInfo.rejected, (state, action) => {
			state.loading = false;
			state.currentCompany = null;
		});
		builder.addCase(fetchCompanyInfo.fulfilled, (state, action) => {
			let { isCompanyProfileCompleted } = action.payload;
			state.currentUser = { ...state.currentUser, ...(isCompanyProfileCompleted !== undefined && isCompanyProfileCompleted) };
			state.currentCompany = { ...action.payload };
			state.loading = false;
		});
		builder.addCase(editCompanyInfo.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(editCompanyInfo.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(editCompanyInfo.fulfilled, (state, action) => {
			state.currentCompany = { ...state.currentCompany, ...action.payload };
			state.loading = false;
		});
	}
});

export const {
	setCurrentUser,
	setCurrentTheme,
	setCompanyProfile,
	setUserLocation,
	setUserSubscriptionPackge,
	setDrawerOpen,
	setShowCovidWarning,
	setUsedCurrency,
	setUserActionBeforeAuthSeats,
	setCheckoutData,
	setSelectedVechiles,
	setLang,
	setSelectedB2BRooms,
	setCartCheckoutSubForm,
	setCartIsPaid
} = slice.actions;

export default slice.reducer;
