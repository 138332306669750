import { GetawayAPI, MasterAPI, AuthenticationAPI, PackagesAPI, UmrahAPI, FilesAPI, PaymentAPI, BookingAPI } from "@common/constants";
import { message } from "antd";
import axios from "axios";
let paxios = axios.create();
import Router from "next/router";
import { getSession } from "next-auth/react";

export const generateLocale = () => {
	return Router.router.state.locale;
};

const generateLanguage = () => {
	if (generateLocale() === "ar") return "ar-EG";
	if (generateLocale() === "tr") return "tr-TR";
	return "en-US";
};
const getBaseUrlfromProps = (baseUrl) => {
	switch (baseUrl) {
		case "MasterAPI":
			return MasterAPI;
		case "AuthenticationAPI":
			return AuthenticationAPI;
		case "BookingAPI":
			return BookingAPI;
		case "GetawayAPI":
			return GetawayAPI;
		case "UmrahAPI":
			return UmrahAPI;
		case "FilesAPI":
			return FilesAPI;
		case "PaymentAPI":
			return PaymentAPI;
		default:
			return PackagesAPI;
	}
};

const generateTimeffset = () => {
	const date = new Date();
	const timeOffset = date.getTimezoneOffset();
	return -(timeOffset / 60);
};

// configure axios
paxios.defaults.xsrfCookieName = "csrftoken";
paxios.defaults.xsrfHeaderName = "X-CSRFToken";
paxios["Access-Control-Allow-Origin"] = "*";

paxios.interceptors.response.use(
	function (response) {
		return response?.data;
	},
	function (error) {
		// console.log(error, "errorerror");
		// err.response.data.errors
		if (401 === error?.response.status) {
			// localStorage.removeItem("token");
			// window.location.reload();
		}
		if (500 === error?.response.status) {
			return error?.response?.data;
			// message.error(error?.response.data?.errors?.[0]);
		} else {
			return error?.response?.data;
		}
	}
);

export default class Api {
	static async get(baseUrl, route, headers) {
		return await this.execute(baseUrl, route, null, "get", headers);
	}

	static async put(baseUrl, route, params, headers) {
		return await this.execute(baseUrl, route, params, "put", headers);
	}

	static async post(baseUrl, route, params, headers) {
		return await this.execute(baseUrl, route, params, "post", headers);
	}

	static async delete(baseUrl, route, params, headers) {
		return await this.execute(baseUrl, route, params, "delete", headers);
	}

	static async execute(baseUrl, route, params, verb, headersParam) {
		const base = getBaseUrlfromProps(baseUrl);
		let request = [base + route];
		const session = await getSession();
		const defaultHeader = {
			Authorization: `Bearer ${session?.accessToken}`,
			"Accept-Language": generateLanguage(),
			"Time-Offset": generateTimeffset(),
			// "currency-code": localCurrency ? JSON.parse(localStorage?.getItem("UserCurrency") || "")?.code : "USD",
			"Content-Type": "application/json;"
		};
		let config = {
			headers: headersParam
				? {
						...defaultHeader,
						...headersParam
				  }
				: {
						...defaultHeader
				  }
		};
		if (verb === "delete") {
			config.data = params;
		} else {
			if (params) request.push(params);
		}
		let result = await paxios[verb](...request, config);
		// console.log(result, "resultresult");
		return result;
	}
}
