import { createSlice } from "@reduxjs/toolkit";

interface StateSlice {
    error: any;
    open: boolean;
    openPopup: {
        updatePopup: boolean;
    };
}

const initialState: StateSlice = {
    error: {},
    open: false,
    openPopup: {
        updatePopup: false
    }
};

const slice = createSlice({
    name: "validationPopup",
    initialState,
    reducers: {
        setError: (validationPopup, action) => {
            validationPopup.open = true;
            validationPopup.error = action.payload;
        },
        cleanError: (validationPopup) => {
            validationPopup.open = false;
            validationPopup.error = {};
        },
        togglePopup: (state, action) => {
            state.openPopup.updatePopup = !state.openPopup.updatePopup;
        }
    }
});

export const { setError, cleanError, togglePopup } = slice.actions;

export default slice.reducer;
