import { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { SessionProvider, signOut } from "next-auth/react";
import { appWithTranslation, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { PersistGate } from "redux-persist/integration/react";
import WarningDialog from "@components/dialog/WarningDialog";
import { OrangeTraingleWarning } from "@common/icons";
import { cleanError } from "store/reducers/validationPopup";
import { RootState } from "store/rootReducer";
import { Head } from "next/document";

import { setCompanyProfile, setCurrentTheme, setCurrentUser, setLang, setUserLocation } from "store/reducers/user";
import { ConfigProvider, Spin } from "antd";
import { store, useAppDispatch, wrapper, persistor } from "../store/configureStore";
import Auth from "../components/auth/Auth";
import RouteWrapper from "../components/router/RouteWrapper";
import enUS from "antd/locale/en_US";
import arEG from "antd/locale/ar_EG";
import UpdateDialog from "@components/dialog/UpdateDialog";
import QueryProvider from "../config/react-query";
import "antd/dist/reset.css";
import "/public/assets/common/styleSheets/style.scss";
import * as themes from "../common/themes/schema.json";
import { GlobalStyles } from "../public/assets/common/styleSheets/global/GlobalStyles";
import { css, Global, ThemeProvider } from "@emotion/react";
import { uTypes } from "@components/profile/package/types/UserTypes";
import Api from "@config/network/ApiConfig";
import RootLayout from "app/layout";
import dayjs from "dayjs";
import useLocalState from "@components/router/useLocalState";

function MyApp({ Component, pageProps: { session, ...pageProps } }: any) {
	// Config
	const dispatch = useAppDispatch();
	const router = useRouter();
	const { asPath } = router;
	const { open, error } = useSelector((state: any) => state.validationPopup);
	const { currentUser }: any = useSelector((state: RootState) => state.user);
	const { t, i18n } = asPath.includes("/admin/") ? useTranslation("admin") : useTranslation("website");

	// Is being
	const ISSERVER = typeof window === "undefined";
	const isArabicLang = i18n.language === "ar";
	const isThereRedirectPath: any = ISSERVER ? null : localStorage.getItem("on_login_redirect_path");
	const isExistsLanguage: any = ISSERVER ? null : localStorage.getItem("UserLanguage");
	const browserLocal: any = ISSERVER ? null : window.navigator.language;
	const browserlanguage: any = ISSERVER ? null : browserLocal.split("-")[0];
	const [appState, setAppState] = useState(false);
	const {
		localState: { currentTheme }
	} = useLocalState();
	// console.log(currentUser, "admin 1111111111111111111");
	// Watchers
	useEffect(() => {
		if (currentUser) {
			console.log(currentUser, "777777777777777777777777777")
			// router.push("/admin/security/admins");
		} else {
			router.push("/auth/login");
		}
		!currentUser &&
			session?.accessToken &&
			signOut({ redirect: false }).then(() => {
				router.push(asPath, asPath, { locale: i18n?.language });
				store.dispatch(setCurrentUser(null));
				dispatch(setCompanyProfile(null));
			});
		if (!isExistsLanguage && !ISSERVER) {
			localStorage.setItem("UserLanguage", browserlanguage);
		}
		// GetTenantConfiguration();

		if (currentTheme?.businessStore) {
			router.push(`/${currentTheme?.businessStore}`);
		}
	}, []);

	const validateMessages = {
		required: t(`forms.fields.please_input_field_data`)
	};

	useEffect(() => {
		if (i18n.language) {
			dayjs.locale(i18n.language);
			localStorage.setItem("UserLanguage", i18n.language);
		}
		// Reload translation file on language change
		i18n?.reloadResources();
	}, [i18n.language]);

	useEffect(() => {
		if (isThereRedirectPath === asPath) {
			localStorage.removeItem("on_login_redirect_path");
		}
	}, [asPath]);

	// Handlers
	// const GetTenantConfiguration = () => {
	// 	setAppState(true);
	// 	if (+currentUser?.userType === uTypes?.SuAdmin) {
	// 		dispatch(setCurrentTheme(themes.taqneen));
	// 		localStorage.setItem("TenantConfiguration", JSON.stringify(themes.taqneen));
	// 		setAppState(false);
	// 	} else {
	// 		Api.get("AuthenticationAPI", `/Tenant/GetTenantConfiguration`)
	// 			.then((res) => {
	// 				localStorage.setItem("tenantID", JSON.stringify(res?.responseData?.tenantID));
	// 				localStorage.setItem("TenantName", JSON.stringify(res?.responseData?.tenantName));
	// 				localStorage.setItem("TenantConfiguration", JSON.stringify(res?.responseData));
	// 				dispatch(setCurrentTheme(res?.responseData));
	// 				if (res?.responseData?.businessStore) {
	// 					router.push(`/${res?.responseData?.businessStore}`);
	// 				}
	// 				setAppState(false);
	// 			})
	// 			.catch((err) => {
	// 				setAppState(false);
	// 			});
	// 	}
	// };
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider theme={themes.visitKsa}>
					<Global styles={GlobalStyles} />
					<ConfigProvider
						theme={{
							token: {
								...themes.visitKsa?.token,
								fontFamily: isArabicLang ? "Vazirmatn, sans-serif" : "Poppins ,sans-serif"
							},
							components: {
								Switch: {
									colorPrimary: "#238709"
								}
							}
						}}
						locale={isArabicLang ? arEG : enUS}
						form={{ validateMessages, requiredMark: false, scrollToFirstError: true }}
						virtual
						direction={isArabicLang ? "rtl" : "ltr"}
					>
						<QueryProvider>
							<SessionProvider
								// In case you use a custom path and your app lives at "/cool-app" rather than at the root "//"
								// basePath="cool-app"
								// Re-fetch session every 5 minutes
								refetchInterval={undefined}
								// baseUrl={typeof window !== "undefined" && window.location.hostname}
								// basePath={typeof window !== "undefined" && window.location.hostname}
								// Re-fetches session when window is focused
								refetchOnWindowFocus={false}
								session={session}
							>
								<Spin spinning={appState} indicator={<div></div>} wrapperClassName="app-spin-holder">
									{Component.auth ? (
										<Auth>
											<Component {...pageProps} />
										</Auth>
									) : (
										<RouteWrapper>
											<Component {...pageProps} />
										</RouteWrapper>
									)}
								</Spin>

								<WarningDialog
									icon={<OrangeTraingleWarning />}
									deleteVisibleDialog={open}
									setDeleteVisibleDialog={() => dispatch(cleanError())}
									body={error?.title}
									subBody={error?.body}
									firstFunction={() => dispatch(cleanError())}
									secondFunction={() => dispatch(cleanError())}
									firstActionTitle={error?.firstAction || t("common.buttons.done")}
									secondActionTitle={t("common.buttons.close")}
								/>
								<UpdateDialog translate={{ t, i18n }} />

								{/* <ResNavBar translate={{ t, i18n }} /> */}
							</SessionProvider>
						</QueryProvider>
					</ConfigProvider>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	);
}

export default wrapper.withRedux(appWithTranslation(MyApp));
