// let Host = window.location.hostname;
// let Domain = window.location.origin;
let DirTranslate = "";
let DirStyle = "";
const AuthenticationAPI = process.env.AUTHENTICATION_API;
const PackagesAPI = process.env.PACKAGES_API;
const BookingAPI = process.env.BOOKING_API;
const MasterAPI = process.env.MASTER_API;
const GetawayAPI = process.env.GETAWAY_API;
const UmrahAPI = process.env.UMRAH_API;
const FilesAPI = process.env.FILES_API;
const PaymentAPI = process.env.PAYMENT_API;
const mediaBreackPoint = 1024;
const maxPriceRange = 50000;
const dateFormat = "MMM D, YYYY";
DirTranslate = "common";
DirStyle = `public/assets/common/styleSheets/`;

const FileStatus = {
	New: 0,
	Deleted: 1,
	Modified: 2,
	UnModified: 3,
	0: "New",
	1: "Deleted",
	2: "Modified",
	3: "UnModified"
};

const umrha_location_city = [
	{
		countryCode: "SA",
		country: "Saudi Arabia",
		city: "Makkah",
		city_ar: "مكة المكرمة",
		locationCode: "SA1",
		latitude: 21.4362767,
		longitude: 39.7064571
	},
	{
		countryCode: "SA",
		country: "Saudi Arabia",
		city: "Madinah",
		city_ar: "المدينة المنورة",
		locationCode: "SA4",
		latitude: 24.4714392,
		longitude: 39.3373517
	}
];

const umrahJourneyRadioOptions = {
	makkahToMadina: 3,
	madinaToMakkah: 2,
	makkahOnly: 1
};

const umrahJourneyStepsEnum = {
	hotelsMakkah: 0,
	hotelsMadina: 1,
	companies: 2,
	groundServices: 3,
	mutamer: 4
};

module.exports = {
	dateFormat,
	DirTranslate,
	DirStyle,
	AuthenticationAPI,
	umrha_location_city,
	PackagesAPI,
	BookingAPI,
	GetawayAPI,
	UmrahAPI,
	MasterAPI,
	FilesAPI,
	FileStatus,
	PaymentAPI,
	maxPriceRange,
	mediaBreackPoint,
	umrahJourneyRadioOptions,
	umrahJourneyStepsEnum
};
