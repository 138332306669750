import { QueryClient } from "@tanstack/react-query";
import { message } from "antd";

const onErrorHandler = (error) => {
	const code = error?.response?.status;
	// if (error?.response?.data?.responseMessage) {
	// 	message.error(error?.response?.data?.responseMessage);
	// 	return false;
	// }
	if (code < 400 || code >= 500) {
		// message.error("Sorry. something went wrong .A team of highly trained developers has been dispatched to deal with this situation!");
		return false;
	}
	if (code === 401) {
		// localStorage.clear();
		return null;
	}

	message.error(error?.response?.data?.message || error.toString());
};

export default new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			// refetchOnReconnect: false,
			// retry: 0,
			onSuccess: (res) => {
				// console.log("onSuccess Global queries", res);
				// if (res?.code !== 200) {
				//   notif(res?.message, null, 'success');
				// }
				// if (res?.validation) {
				//   res.validation?.forEach((err) => {
				//     notif(err);
				//   });
				// }
			},
			onError: onErrorHandler,
			networkMode: "always"
		},
		mutations: {
			// onSuccess: (res) => {
			//     console.log("onSuccess Global mutations", res);
			//     if (res.success) {
			//         message.success({
			//             content: res?.data?.message || stampMsg,
			//             duration: 5
			//         });
			//     }
			// },
			// onError: onErrorHandler,
			networkMode: "always"
		}
	}
});
