import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import { persistStore } from "redux-persist";
import persistReducer from "./rootReducer";

let middlewares = [];

if (process.env.NODE_ENV === "development") {
    middlewares = [];
} else {
    middlewares = [];
}

export const store = configureStore({
    reducer: persistReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat()
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const makeStore = () => store;
export const wrapper = createWrapper(makeStore);
export let persistor = persistStore(store);
