import { reducerTypes } from "@helpers/interfaces";
import { createSlice } from "@reduxjs/toolkit";

const initialState: reducerTypes = {
    error: [],
    loading: false,
    data: false,
    currency: false
};

const slice: any = createSlice({
    name: "admin",
    initialState,
    reducers: {
        AdminRequest: (admin) => {
            admin.loading = true;
            admin.error = false;
        },
        AdminClear: (admin) => {
            admin.error = false;
            admin.loading = false;
        },
        AdminSuccess: (admin, action) => {
            admin.loading = false;
            admin.error = false;
            admin.data = action?.payload;
        },
        AdminError: (admin, action) => {
            admin.loading = false;
            admin.error = action?.payload;
        },
        CurrencyRequest: (admin) => {
            admin.loading = true;
            admin.error = false;
        },
        CurrencySuccess: (admin, action) => {
            admin.loading = false;
            admin.error = false;
            admin.currency = action?.payload;
        },
        CurrencyError: (admin, action) => {
            admin.loading = false;
            admin.error = action?.payload;
        },
        CurrencyClear: (admin, action) => {
            admin.error = false;
            admin.loading = false;
        }
    }
});

export const { AdminRequest, AdminClear, AdminSuccess, AdminError, CurrencySuccess, CurrencyError, CurrencyClear, CurrencyRequest } = slice.actions;

export default slice.reducer;
