import { createSlice } from "@reduxjs/toolkit";

type StateSlice = {
    cities: any[];
    hotels: any;
    countries: any[];
    destinations: any[];
    languages: any[];
    lookups: any[];
    nationality: { id: number; name: string }[];
    regions: any[];
    currencies: any[];
    PackageTypes: any[];
    PackageItemTypes: any[];
    providers: any[];
    routes: any[];
    vechicleTypes: any[];
    transportationCategories: any[];
};

const initialState: StateSlice = {
    cities: [],
    hotels: null,
    countries: [],
    destinations: [],
    languages: [],
    lookups: [],
    nationality: [],
    currencies: [],
    regions: [],
    PackageTypes: [],
    PackageItemTypes: [],
    providers: [],
    routes: [],
    transportationCategories: [],
    vechicleTypes: []
};

const slice = createSlice({
    name: "masterdata",
    initialState,
    reducers: {
        setMasterData: (masterData, action) => {
            const { type, data } = action.payload;
            type ObjectKey = keyof StateSlice;
            const typeKey = type as ObjectKey;
            masterData[typeKey] = data;
        },
        resetMasterData: (masterdata) => {
            masterdata.cities = [];
            masterdata.destinations = [];
            masterdata.PackageTypes = [];
            masterdata.PackageItemTypes = [];
        }
    }
});

export const { setMasterData, resetMasterData } = slice.actions;

export default slice.reducer;
