import { css } from "@emotion/react";
export const GlobalStyles = (theme) => css`
	* {
		&::selection {
			background-color: ${theme.token.colorPrimary};
			color: ${theme.token.colorTextBase};
		}
	}
	.ant-btn {
		&.end {
			margin-inline-start: auto;
		}

		&.center {
			margin: auto;
		}

		&.small {
			min-width: 11rem;
			min-height: 5rem;
			font-weight: normal;
		}

		img {
			margin-inline-end: 1rem;
		}
		&.ant-btn-link {
			height: auto;
			padding: 0;
			color: ${theme.token.colorPrimary};
			a {
				text-decoration: underline;
			}
			// span {
			// }
		}
		&:disabled {
			cursor: not-allowed;
			border-color: #d9d9d9 !important;
			color: rgba(0, 0, 0, 0.25) !important;
			background-color: rgba(0, 0, 0, 0.04) !important;
		}
		&.ant-btn-default {
			box-shadow: none;
		}
	}

	.ant-btn-primary {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		font-weight: 600;
		height: 40px;
		min-width: 70px;
		color: ${theme.token.colorBtnColor};
		&:hover {
			color: ${theme.token.colorBtnColor} !important;
		}
		&.ant-btn-background-ghost {
			background-color: transparent;
			border: 1px solid ${theme.token.colorPrimary};
			&:hover {
				background-color: ${theme.token.colorPrimary} !important;
				color: ${theme.token.colorBtnColor} !important;
			}
		}
		&.ant-btn-lg {
			height: 73px;
			font-size: 16px;
		}
		&.ant-btn-md {
			height: 40px;
			font-size: 14px;
		}
		&.ant-btn-sm {
			height: 34px !important;
			font-size: 12px;
		}
		&.primary-light {
			width: auto;
			height: 40px;
			/* height: auto; */
			background: ${theme.token.secBtnBg} !important;
			background: #fff !important;
			color: ${theme.token.colorPrimary} !important;
			a {
				display: flex;
				align-items: center;
				color: ${theme.token.colorPrimary} !important;
			}
		}
		&.green-btn {
			background-color: #238709 !important;
			color: #fff !important;
			a {
				display: flex;
				align-items: center;
				color: #fff !important;
			}
		}
		&.ant-btn-dangerous {
			color: white;
			height: 40px;
			&.ant-btn-background-ghost {
				&:hover {
					background-color: #ff4d4f !important;
					color: #fff !important;
					border: 0 !important;
				}
			}
		}
		&.ant-btn-success {
			background: #57a721;
			color: white;
			height: 40px;
		}
		&.ant-btn-success:hover {
			background: #81c454;
			color: white;
			height: 40px;
		}
		&:disabled {
			color: #fff;
			opacity: 0.6;
		}
	}

	.d-flex {
		display: flex;
	}
	.justify-content-center {
		justify-content: center;
	}
	.justify-content-between {
		justify-content: space-between;
	}
	.align-center {
		align-items: center;
	}
	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: ${theme.token.colorPrimary};
		color: ${theme.token.colorTextBase};
	}

	.ant-picker-cell-in-view.ant-picker-cell-in-range {
		color: ${theme.token.colorTextBase};
	}
	.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
		color: ${theme.token.colorTextBase};
	}

	.ant-picker-panels {
		@media only screen and (max-width: 992px) {
			flex-direction: column;
		}
	}
	.input-border-holder .ant-picker-range {
		.ant-picker-range-separator {
			position: relative;
			left: -25px;
			&:lang(ar) {
				left: auto;
				right: -25px;
			}
		}
	}

	.translate-btn {
		width: 105px;
		height: 36px !important;
		border-radius: 6px;
		font-weight: 500;
		color: ${theme.token.colorTextBase} !important;
		font-size: 12px !important;
		border: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 5px;
		background-color: #333333 !important;
		&.active {
			background-color: ${theme.token.colorPrimary} !important;
		}
		@media only screen and (max-width: 992px) {
			width: auto;
			span {
				display: none;
			}
		}
	}

	.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container {
		opacity: 0.5;
		cursor: not-allowed;
	}

	.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] {
		opacity: 1;
	}

	.ant-modal-content {
		.ant-modal-close-x {
			background-color: transparent;
		}
	}
	.ant-transfer-list-content-item {
		position: relative;
		.ant-transfer-list-content-item-text {
			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
			}
		}
	}

	.dlt-btn {
		&:hover {
			color: #cf2110 !important;
			svg {
				path {
					fill: #cf2110 !important;
				}
			}
		}
	}
	.edit-btn {
		&:hover {
			color: ${theme.token.colorPrimary} !important;
			svg {
				fill: ${theme.token.colorPrimary} !important;
				path,
				g {
					fill: ${theme.token.colorPrimary} !important;
				}
			}
		}
	}
	.ant-layout,
	.ant-drawer {
		.ant-menu-item {
			height: 50px;
			&.ant-menu-item-selected {
				color: ${theme.token.colorPrimary};
				svg {
					path {
						stroke: ${theme.token.colorPrimary};
					}
				}
				&:before {
					content: "";
					position: absolute;
					left: 0px;
					right: 0px;
					width: 8px;
					height: 49px;
					background: ${theme.token.colorPrimary};
					/* border-radius: 30px; */
				}
			}
			svg {
				// fill: #767676;
				g {
					path {
						fill: white;
						stroke: #a3a3a3;
					}
				}
				> path {
					stroke: $secondary_text;
				}
			}
		}

		.ant-menu-submenu-title {
			height: 55px !important;

			.ant-menu-title-content {
				margin-inline-start: 15px;
			}
		}

		.ant-menu-submenu-vertical {
			.ant-menu-title-content {
				margin-inline-start: 22px;
			}
			.ant-menu-submenu-title {
				padding-top: 12px;
			}
		}
	}

	// style for home tabs
	.tabs-no-border {
		.ant-tabs-ink-bar {
			display: none !important;
		}
	}
	.steps-no-icon {
		.ant-steps-item-icon {
			display: none;
		}
	}

	/* start input style */

	.select-holder {
		.ant-select-item {
			margin-bottom: 0.5rem;
		}
		.ant-form-item {
			margin: 0;
		}
		.switch-component {
			.ant-form-item-control-input-content {
				gap: 1.5rem;
				display: flex;
				flex-wrap: nowrap;
			}
			label {
				&::after {
					display: none;
				}
				margin-bottom: 0.5em;
				font-weight: 600;
				font-size: 16px;
				line-height: 1.5;
			}
		}
		.inline-switch {
			margin-bottom: 0;
		}
		.ant-select {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 0;
			.ant-select-selection-placeholder {
				display: flex;
				align-items: center;
			}
			.ant-select-selector {
				width: 100%;
				border: 1px solid #dadde3;
				border-radius: 8px;
				background: #ffffff;
				color: #8d8d8d;
				box-shadow: none !important;
				.ant-select-selection-overflow,
				.ant-select-selection-overflow-item-suffix {
					width: 50px;
				}
				.ant-select-selection-item {
					background-color: #edebff;
					color: ${theme.token.colorPrimary};
					padding: 1rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					line-height: normal !important;
					border: 0 !important;
					input {
						height: 100% !important;
					}
				}
				.ant-select-selection-overflow-item-rest {
					.ant-select-selection-item {
						background: ${theme.token.colorPrimary} !important;
						color: white;
					}
					background: ${theme.token.colorPrimary} !important;
					color: white;
					border-radius: 8px;
				}
				/* .ant-select-selection-search {
                width: 100%;
            } */
			}
		}
	}
	.quill-content-holder {
		border: 1px solid #d9d9d9;
		border-radius: 8px;
		background-color: #fff;
		.ql-snow {
			border: 0;
			&.ql-toolbar {
				border: 0;
				border-bottom: 1px solid #d9d9d9;
			}
			&.ql-container {
				border: 0;
				height: 140px;
				.ql-editor {
					padding-bottom: 30px;
					&:lang(ar) {
						text-align: right;
					}
					* {
						line-height: 1.6;
					}
					&.ql-blank {
						font-size: 16px;
						color: #8d8d8d;
						font-style: normal;
						p {
							font-style: normal;
						}
					}
				}
			}
		}
	}
	/* .translate-btn {
		top: auto !important;
		bottom: 10px;
	} */
	.input-holder {
		margin-bottom: 0;

		.ant-form-item-label {
			::after {
				display: none;
			}
			label {
				font-size: 16px;
				font-weight: 600;
				color: #171717;
				margin: 0;
			}
		}
		.ant-form-item-control-input-content {
			input {
				height: 40px;
				border-radius: 8px;
				background: #ffffff;
				color: #000;
				box-shadow: none;
				&:disabled {
					background: #f5f5f5;
				}
			}
		}
		label::before {
			display: none !important;
		}
		.ant-input-affix-wrapper {
			height: 40px;
			border-radius: 8px;
			background: #ffffff;
			color: #000;
			min-height: 2em;
			width: 100%;
			box-shadow: none;
			input {
				height: 100%;
			}
			padding: 5px;

			&:lang(ar) {
				// direction: ltr !important;
				text-align: right;
				padding-right: 0.5rem;
			}
			&:lang(en) {
				// direction: ltr !important;
				text-align: left;
				padding-left: 0.5rem;
			}
		}
		.flag-dropdown {
			&:lang(ar) {
				left: 0;
			}
		}
		.ant-input-number {
			width: 100%;
			border-radius: 8px;
			box-shadow: none;
		}
		.ant-picker {
			border-radius: 8px;
			box-shadow: none;
			width: 100%;
			height: 40px;
			input {
				height: 100%;
				border-radius: 8px;
				background: #ffffff;
				color: #000;
			}
		}
		.ant-picker-small {
			@extend .ant-picker;
			height: 35px;
			input {
				height: 30px !important;
			}
		}
		.ant-picker-suffix,
		.ant-picker-clear {
			font-size: 22px;
		}
		.ant-select {
			display: flex;
			justify-content: center;
			height: 40px;
			align-items: center;
			border: 0;
			.ant-select-selection-placeholder {
				display: flex;
				align-items: center;
			}
			.ant-select-selector {
				width: 100%;
				height: 40px !important;
				border: 1px solid #dadde3;
				border-radius: 8px;
				background: #ffffff;
				color: #000;
				box-shadow: none !important;
				.ant-select-selection-item,
				.ant-select-selection-search {
					line-height: 40px !important;
					input {
						height: 100% !important;
					}
				}
			}
		}
		.PhoneInput {
			margin-bottom: 0.5rem;
			border: 1px solid #d9d9d9;
			border-radius: 8px;
			padding: 4px 11px;
			height: 40px;
			&.PhoneInput--disabled {
				background: #f5f5f5;
				cursor: not-allowed;
				* {
					cursor: not-allowed;
				}
			}
			&.PhoneInput--focus {
				box-shadow: none !important;
				input {
					border: 0 !important;
					outline: 0 !important;
				}
			}
			.ant-input-prefix {
				margin: 1rem 0;
				margin-right: 2rem;
				margin-left: 1rem;
				font-size: 2rem;
				img {
					object-fit: contain;
				}
			}
			input {
				letter-spacing: 0.14px;
				color: #000;
				font-size: 16px;
				border: 0;
				height: 100%;
				padding: 0;
				direction: ltr;
				text-align: left;
				&:lang(ar) {
					text-align: right !important;
				}
			}
		}

		textarea.ant-input {
			height: 40px;
			border-radius: 8px;
			background: #ffffff;
			color: #000;
			box-shadow: none;
		}

		.ant-upload.ant-upload-select-picture-card {
			width: 100%;
			margin: 0;
			border-style: solid;
			border-radius: 8px;
			background-color: #fff;
		}
		.ant-picker.ant-picker-disabled {
			border-color: transparent;
		}
	}

	.input-border-holder {
		height: 73px;
		max-width: 351px;
		border: 1px solid #bababa;
		border-radius: 12px;
		padding: 4px 16px;
		margin-bottom: 0;
		cursor: pointer;
		&.row-reverse {
			.ant-form-item-control-input-content {
				flex-direction: row-reverse;
			}
		}
		.ant-row,
		.ant-form-item-control,
		.ant-form-item-control-input,
		.ant-form-item-control-input-content {
			height: 100%;
			@media only screen and (max-width: 992px) {
				height: auto;
			}
		}
		.ant-form-item-control-input-content {
			display: flex;
			align-items: center;
			gap: 16px;
			color: #000;
			@media only screen and (max-width: 992px) {
				gap: 10px;
			}
		}
		.ant-row {
			flex-wrap: nowrap;
		}

		.anticon-team,
		.anticon-flag {
			font-size: 25px;
			color: #000;
		}

		.ant-form-item-label {
			user-select: none;
			label {
				color: #4f4f4f;
				font-size: 12px;
				margin-bottom: 0;
				font-weight: normal;
				user-select: none;
			}
			@media only screen and (max-width: 992px) {
				padding-bottom: 0;
				label {
					margin-bottom: 0;
				}
			}
		}

		// rangepicker style
		.ant-picker-range,
		.ant-picker {
			width: 100%;
			height: 100%;
			border: 0;
			padding: 0;
			box-shadow: none;
			.ant-picker-suffix {
				order: -1;
				margin-inline-end: 16px;
				margin-inline-start: 0;
				font-size: 25px;
				color: #000;
			}
			.ant-picker-clear {
				font-size: 20px;
			}
			.ant-picker-input input {
				color: #000;
			}
		}
		// select style
		.ant-form-item-control-input-content .ant-select-selector {
			height: 100%;
			padding: 0;
			border: 0;
			box-shadow: none !important;
			.ant-select-selection-item {
				color: #000;
			}
			.ant-select-selection-search {
				left: 0;
			}
		}
		// select style
		.popover-content {
			padding: 0;
			width: 100%;
			color: #000;
		}
		&.ant-form-item-has-error {
			border-color: #cd3535 !important;
			.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
				border: 0;
			}
			.ant-form-item-explain {
				position: absolute !important;
				top: calc(100% + 6px);
			}
		}
		.ant-input-affix-wrapper {
			display: flex;
			align-items: center;
			gap: 15px;
			border: 0 !important;
			box-shadow: none;
			padding: 0 !important;
			.ant-input-prefix {
				margin: 0;
			}
			input {
				color: #000;
			}
			&::before {
				display: none;
			}
		}
		&.content-between {
			.ant-form-item-control-input-content {
				justify-content: space-between;
			}
		}
	}

	.input-translate-holder,
	.translation-input-holder {
		position: relative;
		.translate-btn {
			position: absolute;
			right: 11px;
			bottom: 10px;
			&.active {
				background-color: ${theme.token.colorPrimary} !important;
			}
			&:lang(ar) {
				right: auto;
				left: 11px;
			}
		}
	}

	.count-row-wrapper {
		display: flex;
		align-items: center;
		.count-row--title {
			color: #2b2f33;
			font-size: 14px;
			font-weight: 500;
			margin-right: 60px;
			user-select: none;
			&:lang(ar) {
				margin-right: 0;
				margin-left: 60px;
			}
		}
		.count-content-holder,
		.count-content {
			.ant-input-number-group {
				.ant-input-number-group-addon {
					background-color: transparent;
					border: 0;
					cursor: pointer;
					padding: 0;
					.anticon {
						color: ${theme.token.colorPrimary};
						font-size: 28px;
						&[disabled] {
							pointer-events: none;
							opacity: 0.3;
						}
					}
				}
			}
			.ant-input-number-input-wrap {
				user-select: none;
			}
			.ant-input-number-input {
				width: 40px;
				padding: 0;
				text-align: center;
				user-select: none;
			}
		}
	}

	.input-custom-label-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.ant-slider-horizontal {
		&.ant-slider {
			.ant-slider-track {
				background-color: ${theme.token.colorPrimary} !important;
			}

			.ant-slider-handle:after {
				box-shadow: 0 0 0 2px ${theme.token.colorPrimary};
			}
		}
	}

	.ant-popover-title {
		user-select: none;
	}
	.ant-input-number-handler {
		@media only screen and (max-width: 768px) {
			display: block !important;
		}
	}
	.ant-input-number-handler-wrap {
		display: none !important;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		margin: 0;
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
	.ant-form-vertical .ant-form-item-label > label {
		font-weight: normal !important;
		// font-size: 15px;
	}

	.checkboxs-border-holder {
		.ant-checkbox-wrapper {
			min-width: 127px;
			height: 40px;
			display: flex;
			align-items: center;
			gap: 5px;
			padding: 0px 14px;
			background-color: #fff;
			border: 1px solid #a7a7a7;
			border-radius: 8px;
			font-weight: 400;
			.ant-checkbox-inner,
			.ant-checkbox {
				top: 0;
			}
			&.ant-checkbox-wrapper-checked {
				border-color: ${theme.token.colorPrimary} !important;
				background-color: #542ef917;
				color: ${theme.token.colorPrimary};
			}
			@media only screen and (max-width: 992px) {
				margin-inline-start: 0;
				padding: 0px 5px;
			}
		}
	}
	.radio-border-holder {
		display: flex;
		.ant-radio-wrapper {
			min-width: auto;
			height: 40px;
			display: flex;
			align-items: center;
			gap: 5px;
			padding: 0px 14px;
			background-color: #fff;
			border: 1px solid #a7a7a7;
			border-radius: 8px;
			font-weight: 400;
			.ant-radio-inner,
			.ant-radio {
				top: 0;
			}
			&.ant-radio-wrapper-checked {
				border-color: ${theme.token.colorPrimary} !important;
				background-color: #542ef917;
				color: ${theme.token.colorPrimary};
			}
			@media only screen and (max-width: 992px) {
				margin-inline-start: 0;
				padding: 0px 5px;
			}
		}
	}
	.mixed-radio-group {
		width: 100%;
		.mixed-label {
			font-size: 14px;
			font-weight: 500;
			color: #2b2f33;
			margin-bottom: 10px;
		}
	}
	.ant-checkbox-inner,
	.ant-checkbox {
		border-radius: 4px;
		&:after {
			border-radius: 4px;
		}
	}

	/* .ant-input-number-handler-wrap {
		display: none !important;
	} */

	.ant-checkbox-wrapper {
		.ant-checkbox {
			.ant-checkbox-inner {
				border-radius: 3px;
			}
		}
	}
	.range-input-holder {
		margin: 8px 0;
		@media only screen and (max-width: 992px) {
			margin: 0 8px;
		}
		.input-title {
			margin-bottom: 20px;
			margin-top: 20px;
		}
		.range-info-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			user-select: none;
			.range-night {
				color: #4f4f4f;
				font-size: 14px;
				user-select: none;
				// font-weight: 500;
			}
			.range-price {
				color: #000000;
				font-size: 14px;
				font-weight: 300;
				user-select: none;
			}
		}
		& > span {
			font-size: 16px;
			font-weight: 600;
			color: ${theme.token.colorPrimary};
			display: block;
		}
		& > p {
			margin: 22px 0px 9px;
			font-size: 16px;
			font-weight: 500;
			color: #8d8d8d;
		}
	}
	.ant-checkbox-group {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}
	.checkbox-group-holder {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		.ant-checkbox-inner {
			display: none;
		}
		.k-checkbox {
			display: none;
		}
		.ant-checkbox-wrapper {
			min-width: 71px;
			height: 29px;
			padding: 3px 10px;
			border: 1px solid ${theme.token.colorPrimary};
			border-radius: 19px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: ${theme.token.colorPrimary};
			font-size: 12px;
			font-weight: 500;
			margin: 0;
			transition: all 0.3s ease-in-out;
			&:hover {
				background-color: ${theme.token.colorPrimary};
				color: ${theme.token.colorBtnColor};
				transition: all 0.3s ease-in-out;
			}
			&.ant-checkbox-wrapper-checked {
				background-color: ${theme.token.colorPrimary};
				color: ${theme.token.colorBtnColor};
			}
		}
		.ant-checkbox-checked::after {
			display: none;
		}
		&.checkbox-stars-holder {
			background-color: transparent;
			.ant-checkbox-wrapper {
				padding: 12px;
				color: #a7a7a7;
				font-size: 16px;
				font-weight: 500;
				min-width: auto;
				/* border: 0 !important; */
				background-color: transparent !important;
				transition: none;
				/* padding-left: 4px;
				padding-right: 4px; */
				border: 1px solid #d6d9d3;
				path {
					fill: #a7a7a7;
				}
				&.ant-checkbox-wrapper-checked {
					background-color: ${theme.token.colorPrimary} !important;
					color: #fff;
					/* color: #ffa700; */
					path {
						fill: #fff;
					}
				}
			}
			.ant-checkbox + span {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				padding: 0;
			}
		}
		@media only screen and (max-width: 992px) {
			gap: 6px;
		}
	}
	.range-night {
		user-select: none;
	}
	.checkbox-group-line-holder {
		.ant-checkbox-group {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
			flex-direction: column;
			@media only screen and (max-width: 992px) {
				flex-direction: row;
			}
		}
		> span {
			display: flex;
			align-items: center;
			.k-checkbox {
				margin-top: 0;
			}
		}
		.ant-checkbox-wrapper {
			width: fit-content;
			padding: 3px 10px;
			color: #000000;
			font-size: 15px;
			font-weight: 500;
			margin: 0;
			transition: all 0.3s ease-in-out;
		}
	}

	.input-label {
		color: #000000;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 8px;
	}
	.ant-checkbox-inner:after,
	.ant-checkbox:after {
		border-radius: 2px;
		display: initial !important;
	}

	.package-type-holder {
		.ant-form-item-label label {
			font-size: 14px;
			user-select: none;
		}
	}

	.packages-wrapper {
		@media only screen and (max-width: 992px) {
			.SelectSection {
				.SelectSection__name {
					display: none;
				}
			}
			.selection-row-holder {
				display: flex;
				align-items: center;
				gap: 4px;
				color: ${theme.token.colorPrimary};
				font-size: 17px;
				font-weight: 500;
				cursor: pointer;
			}
		}
	}
	/* start header styles */

	.profile_dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
		&:hover {
			/* background: ${theme.token.colorPrimary};
			& > span {
				color: ${theme.token.colorTextBase};
			} */
			/* & > svg {
				path {
					stroke: ${theme.token.colorTextBase};
				}
			} */
		}
	}
	.main-color {
		color: ${theme.token.colorPrimary};
	}
	.main-bg {
		background-color: ${theme.token.colorPrimary};
	}
	.sec-bg {
		background-color: ${theme.token.secBtnBg};
	}
	.accent-bg {
		background-color: ${theme.token.accent};
	}

	.price-content-holder {
		color: #000;
		span {
			font-size: 12px;
			color: #6f6f6f;
			font-weight: 500;
		}
	}
	/* .price-content-holder, */
	.ticket_Price--total-price {
		color: ${theme.token.colorPrimary};
	}
	.new-package-wrapper > .newPackage_Body .new_Form .availabilityContainer .availability-type-holder .ant-radio.ant-radio-checked + span {
		color: ${theme.token.colorPrimary};
		font-weight: 500;
	}
	.new-package-wrapper > .newPackage_Body .new_Form .packageTypeContainer .packageTypeBoxes .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .packageTypeBox {
		border-color: ${theme.token.colorPrimary};
	}
	/* .review-container .review-container_whatYouWill_container > .review-container_whatYouWill > .trip_information_active {
		&::before,
		&::after {
			border-color: ${theme.token.colorPrimary};
		}
	} */
	.payment-body-holder .wpwl-button-pay {
		background-color: ${theme.token.colorPrimary};
		color: ${theme.token.colorBtnColor};
	}
	.ant-progress .ant-progress-bg,
	.new-package-wrapper > .newPackage_Stepper_Container > .newPackage_Stepper_Content .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
		background-color: ${theme.token.colorPrimary};
	}
	.currencyModalStyle .ant-modal-content .ant-modal-body .modal_content .currencies_list .currencies .currency_item.active {
		color: ${theme.token.colorPrimary};
	}
	.review-container > .review-container_TripTicket > .review-container_Trip > .review-container_TripStart > span > p,
	.review-container > .review-container_TripTicket > .review-container_Trip > .review-container_TripStart > span > a {
		/* color: ${theme.token.colorPrimary}; */
	}
	.review-container .share-fav-holder .primary-light {
		background-color: ${theme.token.secBtnBg} !important;
		border-color: ${theme.token.secBtnBorderColor};
	}
	.review-container .share-fav-holder .anticon-heart {
		background-color: ${theme.token.secBtnBg};
		border-color: ${theme.token.secBtnBorderColor};
	}
	.ant-select-disabled {
		.ant-select-arrow {
			display: none;
		}
	}

	/* input[type="number"] {
		-moz-appearance: auto !important;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: auto !important;
	} */
`;
