import { Button } from "antd";
import DialogContainer from "./DialogContainer";

const WarningDialog = ({
	deleteVisibleDialog,
	setDeleteVisibleDialog,
	body,
	subBody,
	firstFunction,
	secondFunction,
	icon,
	firstActionTitle,
	secondActionTitle,
	backgroundColor = "#FFF",
	firstButtonBackGroundColor = "#542ef9",
	secondButtonBorderColor = "#542ef9",
	secondButtonColor = "#542ef9"
}: any) => {
	return (
		<DialogContainer style="warnDialogStyle" title="" visible={deleteVisibleDialog} toggleDialog={() => setDeleteVisibleDialog(false)}>
			<div className="body" style={{ backgroundColor }}>
				{icon}
				<p>
					<b>{body}</b>
					{subBody}
				</p>
				<div>
					{firstFunction && (
						<Button className="firstBTN" type="primary" onClick={firstFunction}>
							{firstActionTitle}
						</Button>
					)}
					{secondActionTitle && (
						<Button className="secondBTN" type="primary" ghost onClick={secondFunction}>
							{secondActionTitle}
						</Button>
					)}
				</div>
			</div>
		</DialogContainer>
	);
};

export default WarningDialog;
