import axios from "axios";
import { getSession } from "next-auth/react";
import Router from "next/router";

const generateTimeffset = () => {
	const date = new Date();
	const timeOffset = date.getTimezoneOffset();
	return -(timeOffset / 60);
};

const generateLanguage = () => {
	const Language = Router.router.state.locale;
	if (Language === "ar") return "ar-EG";
	return "en-US";
};

// configure axios
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios["Access-Control-Allow-Origin"] = "*";

const POST = async (url, data, customAllowedHeaders = false) => {
	const session = await getSession();
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const userLocation = localStorage?.getItem("userLocation") && localStorage?.getItem("userLocation") !== "undefined" ? JSON?.parse(localStorage?.getItem("userLocation") || "{}") : null;
	const tenantID = localStorage?.getItem("tenantID") && localStorage?.getItem("tenantID") !== "undefined" ? JSON?.parse(localStorage?.getItem("tenantID") || "{}") : null;
	const TenantName = localStorage?.getItem("TenantName") && localStorage?.getItem("TenantName") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantName") || "{}") : null;

	const head = customAllowedHeaders || {
		headers: {
			Authorization: `Bearer ${session?.accessToken}`,
			"Accept-Language": generateLanguage(),
			"Time-Offset": generateTimeffset(),
			"tenant-id": tenantID || "",
			TenantName: TenantName || "",
			"currency-code": localCurrency ? JSON.parse(localStorage?.getItem("UserCurrency") || "")?.code : "USD",
			countryId: userLocation?.countryId,
			regionId: userLocation?.regionId
		}
	};
	const res = await axios.post(url, data, head);

	return res;
};

const PUT = async (url, data, customAllowedHeaders = false) => {
	const session = await getSession();
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const userLocation = localStorage?.getItem("userLocation") && localStorage?.getItem("userLocation") !== "undefined" ? JSON?.parse(localStorage?.getItem("userLocation") || "{}") : null;
	const tenantID = localStorage?.getItem("tenantID") && localStorage?.getItem("tenantID") !== "undefined" ? JSON?.parse(localStorage?.getItem("tenantID") || "{}") : null;
	const TenantName = localStorage?.getItem("TenantName") && localStorage?.getItem("TenantName") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantName") || "{}") : null;

	const head = customAllowedHeaders || {
		headers: {
			Authorization: `Bearer ${session?.accessToken}`,
			"Accept-Language": generateLanguage(),
			"Time-Offset": generateTimeffset(),
			TenantName: TenantName || "",
			"tenant-id": tenantID || "",
			"currency-code": localCurrency ? JSON.parse(localStorage?.getItem("UserCurrency") || "")?.code : "USD",
			countryId: userLocation?.countryId,
			regionId: userLocation?.regionId
		}
	};
	const res = await axios.put(url, data, head);

	return res;
};

const PATCH = async (url, data) => {
	const session = await getSession();
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const userLocation = localStorage?.getItem("userLocation") && localStorage?.getItem("userLocation") !== "undefined" ? JSON?.parse(localStorage?.getItem("userLocation") || "{}") : null;
	const tenantID = localStorage?.getItem("tenantID") && localStorage?.getItem("tenantID") !== "undefined" ? JSON?.parse(localStorage?.getItem("tenantID") || "{}") : null;
	const TenantName = localStorage?.getItem("TenantName") && localStorage?.getItem("TenantName") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantName") || "{}") : null;

	const head = {
		headers: {
			Authorization: `Bearer ${session?.accessToken}`,
			"Accept-Language": generateLanguage(),
			"Time-Offset": generateTimeffset(),
			TenantName: TenantName || "",
			"tenant-id": tenantID || "",
			"currency-code": localCurrency ? JSON.parse(localStorage?.getItem("UserCurrency") || "")?.code : "USD",
			countryId: userLocation?.countryId,
			regionId: userLocation?.regionId
		}
	};
	const res = await axios.patch(url, data, head);

	return res;
};

const DELETE = async (url, data, dataObject) => {
	const session = await getSession();
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const userLocation = localStorage?.getItem("userLocation") && localStorage?.getItem("userLocation") !== "undefined" ? JSON?.parse(localStorage?.getItem("userLocation") || "{}") : null;
	const tenantID = localStorage?.getItem("tenantID") && localStorage?.getItem("tenantID") !== "undefined" ? JSON?.parse(localStorage?.getItem("tenantID") || "{}") : null;
	const TenantName = localStorage?.getItem("TenantName") && localStorage?.getItem("TenantName") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantName") || "{}") : null;

	const head = {
		headers: {
			Authorization: `Bearer ${session?.accessToken}`,
			"Accept-Language": generateLanguage(),
			"Time-Offset": generateTimeffset(),
			TenantName: TenantName || "",
			"tenant-id": tenantID || "",
			"currency-code": localCurrency ? JSON.parse(localStorage?.getItem("UserCurrency") || "")?.code : "USD",
			countryId: userLocation?.countryId,
			regionId: userLocation?.regionId
		}
	};
	const dataSent = dataObject ? { data: data } : { data: { data: data } };
	const res = await axios.delete(url, { ...dataSent, ...head });

	return res;
};

const GET = async (url, customAllowedHeaders = false, token) => {
	const session = await getSession();
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const userLocation = localStorage?.getItem("userLocation") && localStorage?.getItem("userLocation") !== "undefined" ? JSON?.parse(localStorage?.getItem("userLocation") || "{}") : null;
	const tenantID = localStorage?.getItem("tenantID") && localStorage?.getItem("tenantID") !== "undefined" ? JSON?.parse(localStorage?.getItem("tenantID") || "{}") : null;
	const TenantName = localStorage?.getItem("TenantName") && localStorage?.getItem("TenantName") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantName") || "{}") : null;

	const head = customAllowedHeaders || {
		headers: {
			Authorization: `Bearer ${session?.accessToken || token}`,
			"Accept-Language": generateLanguage(),
			"Time-Offset": generateTimeffset(),
			TenantName: TenantName || "",
			"tenant-id": tenantID || "",
			"currency-code": localCurrency ? JSON.parse(localStorage?.getItem("UserCurrency") || "")?.code : "USD",
			countryId: userLocation?.countryId,
			regionId: userLocation?.regionId
		}
	};
	const res = await axios.get(url, head);

	return res;
};

const exportedObject = { POST, GET, PUT, DELETE, PATCH };

export default exportedObject;
