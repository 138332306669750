// Types
export const uTypes = {
    SuAdmin: 0,
    Admin: 1,
    B2C: 2,
    B2B: 3,
    B2C_B2B: 4
};
export const UserTypes = (t: any) => {
    return [
        {
            label: t(`profile.createPackage.fields.Package Type.types.B2C & B2B`),
            value: uTypes?.B2C_B2B
        },
        {
            label: t(`profile.createPackage.fields.Package Type.types.B2C`),
            value: uTypes?.B2C
        },
        {
            label: t(`profile.createPackage.fields.Package Type.types.B2B`),
            value: uTypes?.B2B
        }
    ];
};
