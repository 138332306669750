import { createSlice } from "@reduxjs/toolkit";

interface SliceState {
    error: boolean | string[];
    loading: boolean;
    data: boolean;
    isModalOpen: boolean;
    isRegister: boolean;
}
const initialState: SliceState = {
    error: [],
    loading: false,
    data: false,
    isModalOpen: false,
    isRegister: false
};

const slice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        AuthRequest: (auth) => {
            auth.loading = true;
            auth.error = false;
        },
        AuthClear: (auth) => {
            auth.error = false;
            auth.loading = false;
        },
        AuthSuccess: (auth, action) => {
            auth.loading = false;
            auth.error = false;
            auth.data = action?.payload;
        },
        AuthError: (auth, action) => {
            auth.loading = false;
            auth.error = action?.payload;
        },
        toggleAuthModal: (user, action) => {
            user.isModalOpen = action?.payload;
            if (!user.isModalOpen) user.isRegister = false;
        },
        changeAuthType: (user) => {
            user.isRegister = !user.isRegister;
        }
    }
});

export const { AuthRequest, AuthClear, AuthSuccess, AuthError, toggleAuthModal, changeAuthType } = slice.actions;

export default slice.reducer;
