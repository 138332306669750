import dayjs from "dayjs";
import * as themes from "@common/themes/schema.json";

const isJsonString = (str: any) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};

const checkLocalStorageValue = (key: any) => (localStorage.getItem(key) && localStorage.getItem(key) !== "undefined" && localStorage.getItem(key) !== null ? true : false);
const parseLocalStorageValue = (key: any) => isJsonString(localStorage.getItem(key) || "{}") || null;
const bigThanOneDay = (date: any) => dayjs().diff(dayjs(date), "day") === 0; // Check if date is big one day

const useLocalState = (): any => {
	const IsServer = typeof window === "undefined";
	if (IsServer) return { localConditions: {}, localState: {} };
	// States
	const localConditions = {
		currencyDate: checkLocalStorageValue("CurrencyDate") && bigThanOneDay(parseLocalStorageValue("CurrencyDate")), // Check if currency date is exist and not today
		userCurrency: checkLocalStorageValue("UserCurrency"), // Check if user currency is exist
		currencyList: checkLocalStorageValue("CurrencyList") && parseLocalStorageValue("CurrencyList")?.length !== 0, // Check if currency list is exist and not empty
		userLocation: checkLocalStorageValue("userLocation"), // Check if user location is exist,
		tenantConfiguration: checkLocalStorageValue("TenantConfiguration")
	};
	const localState = {
		currencyDate: localConditions?.currencyDate ? parseLocalStorageValue("CurrencyDate") : null,
		userCurrency: localConditions?.userCurrency ? parseLocalStorageValue("UserCurrency") : null,
		currencyList: localConditions?.currencyList ? parseLocalStorageValue("CurrencyList") : null,
		userLocation: localConditions?.userLocation ? parseLocalStorageValue("userLocation") : null,
		currentTheme: localConditions?.tenantConfiguration ? parseLocalStorageValue("TenantConfiguration") : themes.dyafat
	};

	return { localConditions, localState };
};

export default useLocalState;
