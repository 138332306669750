import { PercentIcon } from "@common/icons";
import { uTypes } from "@components/profile/package/types/UserTypes";
import { MenuProps } from "antd";
import { features } from "process";
type MenuItem = Required<MenuProps>["items"][number];

const getTypeList = (t: any) => {
	return [
		{ id: 1, label: t("list.value"), value: 0 },
		{ id: 2, label: <PercentIcon />, value: 1 }
	];
};

const getPerList = (t: any) => {
	return [
		{ id: 1, label: t("list.booking"), value: 1 },
		{ id: 2, label: t("list.seats"), value: 2 }
	];
};

const getUserType = (t: any) => {
	return [
		{ id: 1, label: t("profile.B2C"), value: uTypes?.B2C },
		{ id: 2, label: t("profile.B2B"), value: uTypes?.B2B }
	];
};

const validateNum = (value: number) => {
	let v = +value === -1 ? 0 : +value;
	v = +v > 100 ? 100 : +v;
	return v;
};

const validateType = (type: number, value: number) => {
	//If percentage
	if (type === 1) {
		return +validateNum(value) || 0;
	} else {
		return +value || 0;
	}
};
export const gategories = {
	markup: "/admin/markup/",
	security: "/admin/security/",
	tenants: "/admin/tenants/",
	companies: "/admin/companies/",
	doctors: "/admin/doctors/",
	patients: "/admin/patients/",
	packages: "/admin/packages/",
	settings: "/admin/settings/",
	chat: "/admin/chat/",
	transactions: "/admin/transactions/",
	balances: "/admin/balances/",
	plans: "/admin/plans/",
	features: "/admin/features/",
	UpcomingSessions: "/admin/chat/",
	pages: "/admin/pages/"
};

// Get Category by role
export const getCategoryByRole: any = {
	markup: ["PackageMarkups", "TransportationMarkup", "HotelMarkup", "ActivityMarkup", "GroundServiceMarkup"],
	security: ["Admins", "Roles"],
	companies: ["CompanyPendingList", "CompanyApprovedList"],
	doctors: ["CompanyPendingList", "CompanyApprovedList"],
	tenants: ["Tenant"],
	packages: ["PackageTypes", "PackageItems", "ThingsToDoItems", "ThingsToDoTypes"],
	settings: ["Regions", "Countries", "Cities", "Languages", "Nationalities", "Currencies"]
};

// Get page by role
export const getPageByRole: any = {
	// Markup
	PackageMarkups: {
		gategory: gategories?.markup,
		path: "packages"
	},
	TransportationMarkup: {
		gategory: gategories?.markup,
		path: "transportation"
	},
	HotelMarkup: {
		gategory: gategories?.markup,
		path: "stays"
	},

	// Security
	Admins: {
		gategory: gategories?.security,
		path: "admins"
	},
	Roles: {
		gategory: gategories?.security,
		path: "roles"
	},

	// Tenants
	Tenant: {
		gategory: gategories?.tenants,
		path: "tenant"
	},

	// Companies
	CompanyApprovedList: {
		gategory: gategories?.companies,
		path: "approval"
	},
	CompanyPendingList: {
		gategory: gategories?.companies,
		path: "all_doctors"
	},

	// Packages
	PackageTypes: {
		gategory: gategories?.packages,
		path: "PackageTypes"
	},
	PackageItems: {
		gategory: gategories?.packages,
		path: "PackageItems"
	},
	ThingsToDoTypes: {
		gategory: gategories?.packages,
		path: "ThingsToDoTypes"
	},
	ThingsToDoItems: {
		gategory: gategories?.packages,
		path: "ThingsToDoItems"
	},

	// Settings
	Regions: {
		gategory: gategories?.settings,
		path: "region"
	},
	Countries: {
		gategory: gategories?.settings,
		path: "country"
	},
	Cities: {
		gategory: gategories?.settings,
		path: "city"
	},
	Nationalities: {
		gategory: gategories?.settings,
		path: "nationality"
	},
	Currencies: {
		gategory: gategories?.settings,
		path: "currency"
	}
};
const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem => {
	return {
		key,
		icon,
		children,
		label,
		type
	} as MenuItem;
};

export { validateNum, validateType, getItem, getUserType, getTypeList, getPerList };
