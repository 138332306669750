import { useEffect } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

function Auth({ children }: any): any {
	const router = useRouter();
	// if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
	const { data: session, status } = useSession({ required: false });
	useEffect(() => {
		if (status === "loading") return; // Do nothing while loading
		if (!session) router.push("/auth/login"); //Redirect to login
	}, [session, status, router]);

	if (session) return children;
}
export default Auth;
